<script setup>
import axios from 'axios';

const isDirty = defineModel('isDirty', {
    type: Boolean,
    required: true,
});

const journey = defineModel('journey', {
    type: Object,
    required: true,
});

const originalJourney = defineModel('originalJourney', {
    type: Object,
    required: true,
});

const props = defineProps({
    saveUrl: String,
});

const saveJourney = () => {
    axios.post(props.saveUrl, journey.value)
        .then(() => {
            isDirty.value = false;
            originalJourney.value = JSON.parse(JSON.stringify(journey.value));
        })
        .catch(error => {
            alert(error);
        });
};
</script>

<template>
    <button class="btn btn--primary" @click="saveJourney" :disabled="!isDirty">Save</button>
</template>
