<script setup>
import { nextTick } from 'vue';

const isDirty = defineModel('isDirty', {
    type: Boolean,
    required: true,
});

const journey = defineModel('journey', {
    type: Object,
    required: true,
});

const props = defineProps({
    originalJourney: Object,
});

// Reset function that restores journey to its original state
const resetJourney = () => {
    journey.value = JSON.parse(JSON.stringify(props.originalJourney));
    nextTick(() => {
        isDirty.value = false;
    });
};
</script>

<template>
    <button class="btn btn--secondary" @click="resetJourney" :disabled="!isDirty">Reset</button>
</template>
