<script setup>
import {ulid} from 'ulid';

const blocks = defineModel({
    type: Array,
    required: true,
});

const addBlock = () => {
    blocks.value.push({
        id: ulid(),
        after: blocks.value.length > 0 ? blocks.value[blocks.value.length - 1].after + 1 : 0,
        steps: [],
    });
};
</script>

<template>
    <button class="btn btn--primary" @click="addBlock">Add block</button>
</template>
