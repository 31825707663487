<script setup>
import {VueDraggable} from "vue-draggable-plus";
import Step from "./Step.vue";
import RemoveStepButton from "./RemoveStepButton.vue";

const steps = defineModel({
    type: Array,
    required: true,
});
</script>

<template>
    <VueDraggable v-model="steps" group="steps">
        <div v-for="(step, index) in steps" :key="step.id">
            <Step v-model="steps[index]" />
            <RemoveStepButton v-model="steps" :index="index"/>
        </div>
    </VueDraggable>
</template>
