<script setup>
import {ulid} from 'ulid';

const steps = defineModel({
    type: Array,
    required: true,
});

const addStep = (type) => {
    switch (type) {
        case 'NotifySuccessManagerStep':
            steps.value.push({
                id: ulid(),
                type: type,
                message: '',
            });
            break;

        case 'SendEmailStep':
            steps.value.push({
                id: ulid(),
                type: type,
                body: {
                    html: '',
                    json: {
                        type: 'doc',
                        content: [],
                    },
                },
            });
            break;
    }
};
</script>

<template>
    <div>
        <button class="btn btn--primary" @click="addStep('NotifySuccessManagerStep')">Add "Notify Success Manager" step</button>
        <button class="btn btn--primary" @click="addStep('SendEmailStep')">Add "Send email" step</button>
    </div>
</template>
