<script setup>
import AddStepButton from "./AddStepButton.vue";
import Steps from "../Step/Steps.vue";

const block = defineModel({
    block: { type: Object, required: true},
});
</script>

<template>
    <h2 v-if="block.after === 0">Immediately</h2>
    <h2 v-else>After {{ block.after }} days</h2>
    <Steps v-model="block.steps"/>
    <AddStepButton v-model="block.steps"/>
</template>
