<template>
    <label v-if="editor !== null">
        <input
            type="checkbox"
            @click="editor.chain().focus().toggleBold().run()"
            :checked="editor.isActive('bold')">
        Bold
    </label>
    <editor-content :editor="editor" />
</template>

<script>
// See https://tiptap.dev/docs/editor/getting-started/install/vue3

import { Editor, EditorContent } from '@tiptap/vue-3';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Gapcursor from '@tiptap/extension-gapcursor';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import suggestion from './suggestion.js'

export default {
    components: {
        EditorContent,
    },

    props: {
        modelValue: {
            type: Object,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            editor: null,
        };
    },

    watch: {
        modelValue(value) {
            const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value.json);
            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value.json, false);
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                Bold,
                Document,
                Gapcursor,
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion,
                }),
                Paragraph,
                Text,
            ],
            content: this.modelValue.json,
            onUpdate: () => {
                this.$emit('update:modelValue', {
                    html: this.editor.getHTML(),
                    json: this.editor.getJSON(),
                });
            },
        });
    },

    beforeUnmount() {
        this.editor.destroy();
    },
}
</script>

<style lang="scss">
/* See https://tiptap.dev/docs/examples/advanced/mentions */
.tiptap {
    .mention {
        background-color: mediumpurple;
        border-radius: 0.4rem;
        box-decoration-break: clone;
        color: purple;
        padding: 0.1rem 0.3rem;
    }
}

/* Basic editor styles */
.tiptap {
    border: 1px solid lightgrey;

    :first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 0.4rem;

        li p {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        text-wrap: pretty;
    }

    h1,
    h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }

    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: black;
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: black;
        border-radius: 0.5rem;
        color: white;
        font-family: 'JetBrainsMono', monospace;
        margin: 1.5rem 0;
        padding: 0.75rem 1rem;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
        }
    }

    blockquote {
        border-left: 3px solid var(--gray-3);
        margin: 1.5rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid var(--gray-2);
        margin: 2rem 0;
    }

    .tiptap p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
}
</style>
