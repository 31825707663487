<script setup>
const step = defineModel({
    type: Object,
    required: true,
});
</script>

<template>
    <div>
        <p>
            Message: <input type="text" v-model="step.message">
        </p>
    </div>
</template>
