<script setup>
import AddBlockButton from "./AddBlockButton.vue";
import Block from "./Block.vue";
import RemoveBlockButton from "./RemoveBlockButton.vue";
import { nextTick } from "vue";
import { VueDraggable } from "vue-draggable-plus";

const blocks = defineModel({
    type: Array,
    required: true,
});

const onEndDrag = async (event) => {
    await nextTick();

    if (event.newIndex === 0) {
        event.data.after = 0;
    } else {
        event.data.after = blocks.value[event.newIndex - 1].after + 1;
    }
};
</script>

<template>
    <VueDraggable v-model="blocks" @end="onEndDrag">
        <div v-for="(block, index) in blocks" :key="block.id">
            <Block v-model="blocks[index]"/>
            <RemoveBlockButton v-model="blocks" :index="index"/>
            <hr v-if="index !== blocks.length - 1"/>
        </div>
    </VueDraggable>
    <AddBlockButton v-model="blocks"/>
</template>
