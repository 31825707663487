<script setup>
const blocks = defineModel({
    type: Array,
    required: true,
});

const props = defineProps({
    index: {
        type: Number,
        required: true,
    },
});

const removeBlock = () => {
    blocks.value.splice(props.index, 1);
};
</script>

<template>
    <button class="btn btn--danger" @click="removeBlock">Remove block</button>
</template>
