<script setup>
import { ref } from 'vue';

import Editor from "../../Editor/Editor.vue";

const step = defineModel({
    type: Object,
    required: true,
});

const language = ref('DE');
</script>

<template>
    <div>
        <label><input type="radio" v-model="language" value="DE"/>German</label>
        <label><input type="radio" v-model="language" value="EN"/>English</label>
    </div>
    <div>
        <p v-show="language === 'DE'">
            Body: <Editor v-model="step.body"/>
        </p>
        <p v-show="language === 'EN'">
            To be implemented
        </p>
    </div>
</template>
