<script setup>
import SendEmailStep from "./SendEmailStep.vue";
import NotifySuccessManagerStep from "./NotifySuccessManagerStep.vue";

const step = defineModel({
    type: Object,
    required: true,
});
</script>

<template>
    <div>
        <h3>{{ step.type }}</h3>

        <div v-if="step.type === 'SendEmailStep'">
            <SendEmailStep v-model="step" />
        </div>
        <div v-else-if="step.type === 'NotifySuccessManagerStep'">
            <NotifySuccessManagerStep v-model="step" />
        </div>
    </div>
</template>
